import isEmpty from 'lodash/isEmpty'

import { MIN_LENGTH, MAX_LENGTH, APPROVE_STATUS } from '../../../constants/Constants'

export function validBlogTitle(value) {
  const isEmptyString = isEmpty(value)
  const length = value.length

  const result = !isEmptyString && length >= MIN_LENGTH.BLOG_TITLE && length <= MAX_LENGTH.BLOG_TITLE

  return result
}

export function isQuillEmpty(value) {
  if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes('<img')) {
    return true
  }

  return false
}

export function validBlogContent(value) {
  const isEmptyString = isQuillEmpty(value)
  const length = value.length
  const result = !isEmptyString && length >= MIN_LENGTH.BLOG_CONTENT && length <= MAX_LENGTH.BLOG_CONTENT

  return result
}

export function getTitleBlogByStatus(status) {
  let title = 'document.status_waiting'
  if (status === APPROVE_STATUS.APPROVED) {
    title = 'document.status_accepted'
  } else if (status === APPROVE_STATUS.DENIED) {
    title = 'document.status_denied'
  }

  return title
}

export function getColorBlogByStatus(status) {
  let color = 'black'
  if (status === APPROVE_STATUS.APPROVED) {
    color = 'green'
  } else if (status === APPROVE_STATUS.DENIED) {
    color = 'red'
  }

  return color
}

export function getTagColorByRemovedAndStatus(isDel, status) {
  let color = 'black'
  if (isDel === true) {
    color = 'red'
  } else {
    color = getColorBlogByStatus(status)
  }

  return color
}

export function getTagTitleByRemovedAndStatus(isDel, status) {
  let title = 'document.status_waiting'
  if (isDel === true) {
    title = 'general.removed'
  } else {
    title = getTitleBlogByStatus(status)
  }

  return title
}
