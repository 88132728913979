//  ** Constants

// ** Icons Imports
// import GoogleCirclesExtended from 'mdi-material-ui/GoogleCirclesExtended'
import BookOpen from 'mdi-material-ui/BookOpen'
import Headphones from 'mdi-material-ui/Headphones'
import PencilBoxMultipleOutline from 'mdi-material-ui/PencilBoxMultipleOutline'
import AccountVoice from 'mdi-material-ui/AccountVoice'

export const ENGLISH_SKILL_KEY = {
  ALL: 'all',
  READING: 'reading',
  LISTENING: 'listening',
  WRITING: 'writing',
  SPEAKING: 'speaking'
}

export const FULL_SKILL_DATA = [
  // { key: ENGLISH_SKILL_KEY.ALL, icon: <GoogleCirclesExtended />, label: 'All', keywords: '', checked: true },
  {
    key: ENGLISH_SKILL_KEY.LISTENING,
    icon: <Headphones />,
    label: 'Listening',
    keywords: 'listening',
    checked: false
  },
  {
    key: ENGLISH_SKILL_KEY.READING,
    icon: <BookOpen />,
    label: 'Reading',
    keywords: 'reading',
    checked: false
  },
  {
    key: ENGLISH_SKILL_KEY.WRITING,
    icon: <PencilBoxMultipleOutline />,
    label: 'Writing',
    keywords: 'writing',
    checked: false
  },
  {
    key: ENGLISH_SKILL_KEY.SPEAKING,
    icon: <AccountVoice />,
    label: 'Speaking',
    keywords: 'speaking',
    checked: false
  }
]

export const SPEAKING_PART_KEY = {
  PART_1: 'part_1',
  PART_2_3: 'part_2_3'
}

export const LISTENING_PART_KEY = {
  PART_1: 'part_1',
  PART_2: 'part_2',
  PART_3: 'part_3',
  PART_4: 'part_4'
}

export const READING_PART_KEY = {
  PART_1: 'part_1',
  PART_2: 'part_2',
  PART_3: 'part_3'
}

export const WRITING_PART_KEY = {
  PART_1: 'part_1',
  PART_2: 'part_2'
}

export const FULL_SPEAKING_PART_DATA = [
  {
    key: SPEAKING_PART_KEY.PART_1,
    label: 'Part 1'
  },
  {
    key: SPEAKING_PART_KEY.PART_2_3,
    label: 'Part 2 & 3'
  }
]

export const FULL_WRITING_PART_DATA = [
  {
    key: WRITING_PART_KEY.PART_1,
    label: 'Task 1'
  },
  {
    key: WRITING_PART_KEY.PART_2,
    label: 'Task 2'
  }
]

export const FULL_READING_PART_DATA = [
  {
    key: READING_PART_KEY.PART_1,
    label: 'Passage 1'
  },
  {
    key: READING_PART_KEY.PART_2,
    label: 'Passage 2'
  },
  {
    key: READING_PART_KEY.PART_3,
    label: 'Passage 3'
  }
]

export const FULL_READING_PART_GROUP_DATA = [
  {
    key: LISTENING_PART_KEY.PART_1,
    label: 'Passage 1,2 & 3'
  }
]

export const FULL_LISTENING_PART_DATA = [
  {
    key: LISTENING_PART_KEY.PART_1,
    label: 'Part 1'
  },
  {
    key: LISTENING_PART_KEY.PART_2,
    label: 'Part 2'
  },
  {
    key: LISTENING_PART_KEY.PART_3,
    label: 'Part 3'
  },
  {
    key: LISTENING_PART_KEY.PART_4,
    label: 'Part 4'
  }
]

export const FULL_LISTENING_PART_GROUP_DATA = [
  {
    key: LISTENING_PART_KEY.PART_1,
    label: 'Part 1,2,3 & 4'
  }
]

export const EXAM_TYPE_KEY = {
  NONE: 'none',
  COMPUTER: 'computer',
  PAPER: 'paper'
}

export const titleExamTypeComputer = 'Thi máy'

export const titleExamTypePaper = 'Thi giấy'
