import { useRef, useState, useEffect } from 'react'

// Services
import { subscribeCounterData } from '../normal'
import { getAllResultData, getRelatedResultBySlug } from '../result'

// Utils
import isEmpty from 'lodash/isEmpty'

//  Constants
import { COLLECTION_NAME } from '../../../../constants/Constants'

export const useResultData = () => {
  // State
  const [resultDataList, setResultDataList] = useState([])
  const [resultCounterData, setResultCounterData] = useState({})

  //  Ref
  const unsubscribeResultDataListRef = useRef()
  const unsubscribeResultCounterDataRef = useRef(null)

  //
  useEffect(() => {
    return () => {
      // Cleanup here
      clearAllSubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getCurrentAllResultData(previousPage, currentPage, numberLimit, lastTime, time) {
    const bookData = await getAllResultData(previousPage, currentPage, numberLimit, lastTime, time)
    setResultDataList(bookData)
  }

  async function getRelatedData(testDate) {
    const bookData = await getRelatedResultBySlug(testDate)
    setResultDataList(bookData)
  }

  async function subscribeResultCounter() {
    if (unsubscribeResultCounterDataRef.current === null) {
      const name = COLLECTION_NAME.RESULT_IELTS
      
      const unsubscribe = await subscribeCounterData(name, async (counterData, error) => {
        if (error) {
          console.log('subscribeResultCounter error:', error)
        } else if (!isEmpty(counterData)) {
          setResultCounterData(counterData)
        }
      })
      unsubscribeResultCounterDataRef.current = unsubscribe
    }
  }

  function clearAllSubscribe() {
    if (unsubscribeResultDataListRef.current) {
      unsubscribeResultDataListRef.current && unsubscribeResultDataListRef.current()
    }
    if (unsubscribeResultCounterDataRef.current) {
      unsubscribeResultCounterDataRef.current && unsubscribeResultCounterDataRef.current()
    }
  }

  return {
    resultDataList,
    resultCounterData,
    actions: {
      getCurrentAllResultData,
      // getCurrenPredictionDataByType,
      getRelatedData,
      subscribeResultCounter,
      clearAllSubscribe
    }
  }
}
