import firebase_app from '../firebaseConfig'
import { getFirestore, collection, query, where, onSnapshot } from 'firebase/firestore'

const db = getFirestore(firebase_app)

// Constants
import { COLLECTION_NAME } from '../../../constants/Constants'

// User
export const subscribeDiscountData = async (time, callback) => {
  const userColRef = collection(db, COLLECTION_NAME.DISCOUNT)

  // Create a query against the collection.
  const q = query(userColRef, where('toTime', '>=', time))

  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      const discounts = []
      querySnapshot.forEach(doc => {
        discounts.push(doc.data())
      })
      let discountData = {}
      if (discounts.length > 0) {
        for (let i = 0; i < discounts.length; i++) {
          const discountItem = discounts[i]
          if (discountItem.fromTime <= time) {
            discountData = discountItem
            break
          }
        }
      }
      callback(discountData, null)
    },
    error => {
      // ...
      callback(null, error)
    }
  )

  return unsubscribe
}
