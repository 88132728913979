// ** Next Imports
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'

import React, { useEffect, useState, forwardRef } from 'react'

import { m } from 'framer-motion'

//  ** ant design
import { Empty } from 'antd'

import CircularProgress from '@mui/material/CircularProgress'
import MenuItem from '@mui/material/MenuItem'
import InputLabel from '@mui/material/InputLabel'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Button from '@mui/material/Button'

import OutlinedInput from '@mui/material/OutlinedInput'
import EmoticonHappyOutline from 'mdi-material-ui/EmoticonHappyOutline'

import CardResultItemList from 'src/@core/components/result/CardResultItemList'

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TablePagination from '@mui/material/TablePagination'
import TextField from '@mui/material/TextField'
// ** Third Party Imports
import DatePicker from 'react-datepicker'

// ** Styled Components
import DatePickerWrapper from 'src/@core/styles/libs/react-datepicker'
// ** Third Party Styles Imports
import 'react-datepicker/dist/react-datepicker.css'

// import { styled } from '@mui/material/styles'
// ** Utils
import { getObjectValueWithDefaultValue } from 'src/@core/utils/utils'
import isEmpty from 'lodash/isEmpty'
import { firstOfMonth, formatYYYYMMDDStringFromDay, formatStringFromDay } from 'src/@core/utils/date'

import TableEye from 'mdi-material-ui/TableEye'

//  Services
import { useResultData } from 'src/@core/services/firestore/hooks/useResultData'
import { useDiscountData } from 'src/@core/services/firestore/hooks/useDiscountData'
import { useTranslation } from 'next-i18next'
// import useLocalStorageState from 'use-local-storage-state'

// ** Themes
import { useTheme } from '@mui/material/styles'

// Redux
import { connect } from 'react-redux'
import { compose } from 'redux'
import { updateDeviceInfo } from 'src/@core/redux/actions'

// ** SSR
import getServerSideProps from 'src/@core/services/ssr/serverProps'

export { getServerSideProps }

//  Constants
import {
  PAGINATION,
  REVIEW_SORT_BY_KEY,
  RESULT_DISPLAY_KEY,
  RESULT_DISPLAY_TYPE,
  RESULT_FILTER_TYPE,
  COLOR_BLUE_LIGHT
} from 'src/constants/Constants'

// const isBrowser = typeof window !== 'undefined'

const ResultPage = props => {
  const { t } = useTranslation()

  const resultHookData = useResultData()
  const discountDataHook = useDiscountData()
  const theme = useTheme()

  // ** State

  // const [examDate, setExamDate] = useLocalStorageState('filter_date_result_key', {
  //   defaultValue: String(firstOfMonth().getTime())
  // })

  // const [filterType, setFilterType] = useLocalStorageState('filter_type_result_key', {
  //   defaultValue: REVIEW_SORT_BY_KEY.ALL
  // })

  // const [displayType, setDisplayType] = useLocalStorageState('filter_display_result_key', {
  //   defaultValue: RESULT_DISPLAY_KEY.GRID
  // })
  const [examDate, setExamDate] = useState(String(firstOfMonth().getTime()))

  const [filterType, setFilterType] = useState(REVIEW_SORT_BY_KEY.ALL)

  const [displayType, setDisplayType] = useState(RESULT_DISPLAY_KEY.GRID)

  const [totalItem, setTotalItem] = useState(0)

  // Paging
  const [reviewDataList, setReviewDataList] = useState([])
  const [eventDataList, setEventDataList] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  //
  const DEFAULT_PAGE = 1
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)
  const [rowsPerPage] = useState(PAGINATION.REVIEW)

  const CustomInput = forwardRef((props, ref) => {
    return (
      <TextField
        size='small'
        sx={{ width: '115px', marginLeft: 2 }}
        inputProps={{
          style: { fontSize: 14 }
        }}
        inputRef={ref}
        label={props.title}
        {...props}
      />
    )
  })

  // ** Hooks
  const router = useRouter()

  useEffect(() => {
    if (!isEmpty(props.deviceInfo)) {
      props.updateDeviceInfo(props.deviceInfo)
    }
    if (isEmpty(discountDataHook.discountInfo)) {
      const strDateTime = String(new Date().getTime())
      discountDataHook.actions.subscribeMyDiscountData(strDateTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.deviceInfo])

  useEffect(() => {
    const events = reviewDataList.map(reviewItem => {
      const eventItem = {
        id: reviewItem._id,
        title: reviewItem.title,
        start: formatYYYYMMDDStringFromDay(reviewItem.examDate),
        backgroundColor: COLOR_BLUE_LIGHT,
        textColor: 'white'
      }
      return eventItem
    })
    setEventDataList(events)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewDataList])

  useEffect(() => {
    resultHookData.actions.subscribeResultCounter()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const previousPage = getObjectValueWithDefaultValue(router.query, 'prePage', 1)
    const currentPage = getObjectValueWithDefaultValue(router.query, 'page', 1)
    const numberLimit = rowsPerPage
    //
    let newDisplayType = getObjectValueWithDefaultValue(router.query, 'display', null)
    if (newDisplayType === null) {
      const currentDisplayType = localStorage.getItem('filter_display_result_key') || RESULT_DISPLAY_KEY.GRID
      newDisplayType = currentDisplayType
    }

    //
    let time = getObjectValueWithDefaultValue(router.query, 'time', '0')
    let newFilterType = getObjectValueWithDefaultValue(router.query, 'filter', null)
    if (newFilterType === null) {
      const currentFilterType = localStorage.getItem('filter_type_result_key') || REVIEW_SORT_BY_KEY.ALL
      newFilterType = currentFilterType
      //
      if (newFilterType === 'time') {
        time = String(firstOfMonth().getTime())
      }
    }
    //
    const lastTime = getObjectValueWithDefaultValue(router.query, 'lastTime', '0')

    //
    setFilterType(newFilterType)
    localStorage.setItem('filter_type_result_key', newFilterType)

    setExamDate(time)

    setDisplayType(newDisplayType)
    localStorage.setItem('filter_display_result_key', newDisplayType)

    setCurrentPage(currentPage)
    //
    getCurrentData(previousPage, currentPage, numberLimit, lastTime, time, filterType)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query])

  useEffect(() => {
    calculateTotalItem()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultHookData.resultCounterData])

  function calculateTotalItem() {
    let number = 0
    
    if (!isEmpty(resultHookData.resultCounterData)) {
      number = resultHookData.resultCounterData.numberItemAll
      setTotalItem(number)
    }
  }

  // useEffect(() => {
  //   getCurrentData(previousPage)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [currentPage])

  //
  async function getCurrentData(previousPage, currentPage, numberLimit, lastTime, time, filterType) {
    //
    await resultHookData.actions.getCurrentAllResultData(
      previousPage,
      currentPage,
      numberLimit,
      lastTime,
      time,
      filterType
    )
  }

  useEffect(() => {
    if (!isEmpty(resultHookData.resultDataList)) {
      setReviewDataList(resultHookData.resultDataList)
      //
      setLoadingData(false)
    } else {
      setLoadingData(false)
      setReviewDataList(resultHookData.resultDataList || [])
      // if (loadingData === true) {
      //   console.log('loadingData:', loadingData)
      //   setLoadingData(false)
      //   setReviewDataList(resultHookData.resultDataList || [])
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultHookData.resultDataList])

  const handleChangePage = newPage => {
    if (currentPage && currentPage !== newPage) {
      const fieldValue = 'examDate'
      let lastTimeData = ''
      let previousPageData = currentPage
      let currentPageData = newPage
      const posts = reviewDataList
      if (newPage < currentPage) {
        if (posts.length > 0) {
          const productItem = posts[0]
          lastTimeData = productItem[fieldValue]
        }
      } else {
        if (posts.length > 0) {
          const productItem = posts[posts.length - 1]
          lastTimeData = productItem[fieldValue]
        }
      }
      //
      const time = getObjectValueWithDefaultValue(router.query, 'time', '0')
      //
      const queryURL = {}
      if (previousPageData && previousPageData != currentPageData) {
        queryURL.prePage = previousPageData
      }
      if (currentPageData) {
        queryURL.page = currentPageData
      }
      if (time !== '0') {
        queryURL.time = time
      }
      if (lastTimeData) {
        queryURL.lastTime = lastTimeData
      }
      router.push({
        pathname: router.pathname,
        query: queryURL
      })
    }
    // if (currentPage === newPage - 1 || currentPage === newPage + 1) {
    //   setPreviousPage(currentPage)
    //   setCurrentPage(newPage)
    // }
  }

  const handleClickItem = blogInfo => {
    const path = `/result/${blogInfo.slug}`
    router.push({
      pathname: path
    })
  }

  const handleClickEvent = itemId => {
    const blogInfo = reviewDataList.find(item => item._id === itemId)
    if (blogInfo) {
      const path = `/result/${blogInfo.slug}`
      router.push({
        pathname: path
      })
    }
  }

  const handleChangeRowsPerPage = event => {
    handleChangePage(parseInt(event.target.value, PAGINATION.REVIEW))
  }

  function changeRouter(filter, display, time) {
    const queryURL = { filter, display }

    if (filter !== REVIEW_SORT_BY_KEY.ALL) {
      queryURL.time = time !== '0' ? time : String(firstOfMonth().getTime())
    }

    router.push({
      pathname: router.pathname,
      query: queryURL
    })
  }

  const handleChangeFilter = event => {
    const newFilterType = event.target.value
    //
    changeRouter(newFilterType, displayType, examDate)
  }

  const handleChangeDisplay = event => {
    const newDisplayType = event.target.value
    //
    changeRouter(filterType, newDisplayType, examDate)
  }

  const handleChangeDate = date => {
    changeRouter(filterType, displayType, String(date.getTime()))
  }

  const handleClickUpgrade = () => {
    router.push('/pricing/')
  }

  return (
    <>
      {!isEmpty(discountDataHook.discountInfo) && (
        <RibbonBadge
          placement={'start'}
          marginTop={36}
          text={`-${discountDataHook.discountInfo.discountValue}%`}
          color={theme.palette.warning.main}
        >
          <m.div
            className='cursor'
            onClick={handleClickUpgrade}
            whileHover={{ scale: 1.1 }}
            transition={{ type: 'spring', stiffness: 400, damping: 10 }}
          >
            <Alert
              iconMapping={{
                warning: <EmoticonHappyOutline fontSize='inherit' />
              }}
              // variant='outlined'
              severity='warning'
              sx={{ textAlign: 'left', marginBottom: 4, width: '100%' }}
            >
              <AlertTitle>{`🚀🚀🚀 ${discountDataHook.discountInfo.title}.`}</AlertTitle>

              <Box xs={{ display: 'flex', flexDirection: 'column' }}>
                <Typography>{`${discountDataHook.discountInfo.description} từ ngày ${formatStringFromDay(
                  discountDataHook.discountInfo.fromTime
                )} đến ${formatStringFromDay(discountDataHook.discountInfo.toTime)}`}</Typography>
                <Typography variant='body2' color='textSecondary' sx={{ marginTop: 4 }}>
                  {'Mã giảm giá'}
                </Typography>
                <Button
                  sx={{ marginTop: 2, padding: theme => theme.spacing(1.75, 5.5) }}
                  variant='outlined'
                  color='warning'
                >
                  {discountDataHook.discountInfo.code}
                </Button>
              </Box>
            </Alert>
          </m.div>
        </RibbonBadge>
      )}
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Box>
            <Box sx={{ mt: 1.75, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <TableEye sx={{ marginRight: 3 }} />
                  <Typography variant='h5'>Đáp án đề</Typography>
                </Box>
                <Typography variant='body2'>{'Đáp án đề thi hàng tuần'}</Typography>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <FormControl size='small'>
                  <InputLabel id='form-layouts-separator-multiple-select-label'>Lọc</InputLabel>
                  <Select
                    value={filterType}
                    id='result-filter-select'
                    labelId='result-filter-select-label'
                    onChange={handleChangeFilter}
                    input={<OutlinedInput label='Lọc' id='select-filter-result' />}
                  >
                    {RESULT_FILTER_TYPE.map(itemLanguage => (
                      <MenuItem key={itemLanguage.key} value={itemLanguage.key}>
                        {itemLanguage.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {filterType !== REVIEW_SORT_BY_KEY.ALL && (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='body2' sx={{ marginLeft: 2 }}>
                        Hiển thị
                      </Typography>
                      <FormControl size='small'>
                        <Select
                          value={displayType}
                          id='result-display-select'
                          labelId='result-display-select-label'
                          onChange={handleChangeDisplay}
                          input={<OutlinedInput label='Lọc' id='select-filter-result' />}
                        >
                          {RESULT_DISPLAY_TYPE.map(itemLanguage => (
                            <MenuItem key={itemLanguage.key} value={itemLanguage.key}>
                              {itemLanguage.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant='body2' sx={{ marginLeft: 2 }}>
                        Tháng
                      </Typography>
                      <DatePickerWrapper>
                        <DatePicker
                          selected={new Date(parseInt(examDate))}
                          // showYearDropdown
                          // showMonthDropdown
                          dateFormat='MM/yyyy'
                          showMonthYearPicker
                          id='account-settings-referral-user-from-date'
                          placeholderText='MM-YYYY'
                          customInput={<CustomInput title='Lọc' />}
                          onChange={handleChangeDate}
                        />
                      </DatePickerWrapper>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Grid>
        {reviewDataList.length > 0 ? (
          filterType === 'time' && displayType === RESULT_DISPLAY_KEY.TIME ? (
            <CalendarView date={new Date(parseInt(examDate))} events={eventDataList} onClickEvent={handleClickEvent} />
          ) : (
            reviewDataList.map((reviewItemData, reviewItemIndex) => (
              <CardResultItemList
                needBadge={props.needBadge}
                needAction={props.needAction}
                key={reviewItemData._id}
                isFeedback={props.isFeedback}
                itemData={reviewItemData}
                itemIndex={reviewItemIndex}
                onClickItem={handleClickItem}
              />
            ))
          )
        ) : loadingData === true ? (
          <Box sx={{ pt: 8, width: '100%' }}>
            <CircularProgress
              color='inherit'
              sx={{
                marginLeft: 6
              }}
            />
          </Box>
        ) : (
          <Box sx={{ p: 2, width: '100%' }}>
            <Empty description={<span>{t('general.no_data_to_display')}</span>} />
          </Box>
        )}
        {displayType === RESULT_DISPLAY_KEY.GRID && (
          <Grid item xs={12} sx={{ paddingBottom: 4, width: '100%' }}>
            <TablePagination
              component='div'
              count={totalItem}
              page={currentPage - 1}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

const CalendarView = dynamic(() => import('src/views/result/CalendarView'))
const RibbonBadge = dynamic(() => import('src/@core/layouts/components/shared-components/RibbonBadge'))

export function mapDispatchToProps(dispatch) {
  return {
    updateDeviceInfo: value => dispatch(updateDeviceInfo(value))
  }
}

const withConnect = connect(null, mapDispatchToProps)

export default compose(React.memo, withConnect)(ResultPage)
