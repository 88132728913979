import { useRef, useState, useEffect } from 'react'

// Services
import { subscribeDiscountData } from '../discount'

// Utils
import isEmpty from 'lodash/isEmpty'

// Constants

export const useDiscountData = () => {
  // Ref
  const unsubscribeDiscountDataRef = useRef(null)

  // State
  const [discountInfo, setDiscountInfo] = useState({})

  useEffect(() => {
    //
    return () => {
      // Cleanup here
      clearAllSubscribe()
    }
  }, [])

  // Discount info
  async function subscribeMyDiscountData(time) {
    if (unsubscribeDiscountDataRef.current) {
      unsubscribeDiscountDataRef.current && unsubscribeDiscountDataRef.current()
    }
    
    const unsubscribe = await subscribeDiscountData(time, (discountData, error) => {
      if (error) {
        console.log('loi du lieu subscribeMyDiscountData:', error)
      } else if (!isEmpty(discountData)) {
        setDiscountInfo(discountData)
      }
    })
    unsubscribeDiscountDataRef.current = unsubscribe
  }

  function clearAllSubscribe() {
    // Sub
    if (unsubscribeDiscountDataRef.current) {
      unsubscribeDiscountDataRef.current && unsubscribeDiscountDataRef.current()
    }

    //
    setDiscountInfo({})
  }

  return {
    discountInfo,
    actions: {
      subscribeMyDiscountData,
      clearAllSubscribe
    }
  }
}
