import firebase_app from '../firebaseConfig'
import { getFirestore, where, orderBy, limit, collection, query, getDocs } from 'firebase/firestore'

import {
  lastOfMonth,
  appendNumberMonthFromDate,
  firstDayInMonthFromDate,
  lastDayInMonthFromDate
} from 'src/@core/utils/date'

const db = getFirestore(firebase_app)

// Constants
import { COLLECTION_NAME, SORT_BY_KEY, STATUS_BOOK_LESSON, PAGINATION } from '../../../constants/Constants'

// Utils
import isEmpty from 'lodash/isEmpty'
import { isNumeric } from 'src/@core/utils/utils'

export const getAllResultDataNotCondition = async () => {
  //
  let numberLimitData = 500

  const userColRef = collection(db, COLLECTION_NAME.RESULT_IELTS)

  let q = query(
    userColRef,
    where('isDel', '==', false),
    where('status', '==', STATUS_BOOK_LESSON.PUBLISHED),
    limit(numberLimitData)
  )

  // return unsubscribe
  let lessons = []
  try {
    const docSnap = await getDocs(q)
    docSnap.forEach(doc => {
      lessons.push(doc.data())
    })
  } catch (e) {
    console.log('error:', e)
  }
  return lessons
}

export const getAllResultData = async (previousPage, currentPage, numberLimit, lastTime, time) => {
  //
  let value = '0'
  let operation = '>'
  const sortType = SORT_BY_KEY.DESC
  let sorted = sortType
  const fieldSearch = 'examDate'
  const fieldSort = 'examDate'

  if (currentPage > 1) {
    if (previousPage < currentPage) {
      value = String(lastTime)
      //
      if (sortType === SORT_BY_KEY.DESC) {
        operation = '<'
        sorted = SORT_BY_KEY.DESC
      } else {
        operation = '>'
        sorted = SORT_BY_KEY.ASC
      }
    } else {
      value = String(lastTime)

      //
      if (sortType === SORT_BY_KEY.DESC) {
        operation = '>'
        sorted = SORT_BY_KEY.ASC
      } else {
        operation = '<'
        sorted = SORT_BY_KEY.DESC
      }
    }
  } else if (sortType === SORT_BY_KEY.DESC) {
    sorted = SORT_BY_KEY.DESC
  } else {
    sorted = SORT_BY_KEY.ASC
  }
  //
  let numberLimitData = 50
  if (isNumeric(numberLimit)) {
    if (numberLimit < numberLimitData) {
      numberLimitData = numberLimit
    }
  }

  const userColRef = collection(db, COLLECTION_NAME.RESULT_IELTS)

  let q = query(
    userColRef,
    where('isDel', '==', false),
    where('status', '==', STATUS_BOOK_LESSON.PUBLISHED),
    // where(fieldSearch, operation, value),
    // orderBy(fieldSort, sorted),
    limit(numberLimitData)
  )

  if (!isEmpty(time) && time !== '0') {
    const timeDate = new Date(parseInt(time))
    const fromDate = firstDayInMonthFromDate(timeDate)
    fromDate.setHours(0, 0, 0)
    const iFromDate = String(fromDate.getTime())
    //
    const toDate = lastDayInMonthFromDate(timeDate)
    toDate.setHours(23, 59, 59)
    const iToDate = String(toDate.getTime())
    q = query(q, where('examDate', '<=', iToDate), where('examDate', '>=', iFromDate))
  } else {
    q = query(q, where(fieldSearch, operation, value), orderBy(fieldSort, sorted), limit(numberLimitData))
  }

  // return unsubscribe
  let lessons = []
  try {
    const docSnap = await getDocs(q)
    docSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      lessons.push(doc.data())
    })
    //
    lessons.sort((a, b) => (a[fieldSort] < b[fieldSort] ? 1 : -1))
  } catch (e) {
    console.log('error:', e)
  }
  return lessons
}

export const getResultBySlug = async slug => {
  //
  const collectionName = COLLECTION_NAME.RESULT_IELTS
  const userColRef = collection(db, collectionName)

  // Create a query against the collection.
  const q = query(userColRef, where('slug', '==', slug), limit(PAGINATION.REVIEW))

  const lessons = []
  try {
    const docSnap = await getDocs(q)
    docSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      lessons.push(doc.data())
    })
  } catch (e) {
    console.log(e)
  }

  return lessons
}

export const getRelatedResultBySlug = async time => {
  //
  const collectionName = COLLECTION_NAME.RESULT_IELTS
  const userColRef = collection(db, collectionName)

  const numerMonth = 1
  let fromTime = appendNumberMonthFromDate(time, -numerMonth)
  let toTime = appendNumberMonthFromDate(time, numerMonth)
  //
  const dFromTime = new Date(fromTime)
  fromTime = dFromTime.setDate(1)
  //
  const dToTime = new Date(toTime)
  const lastDayMonth = lastOfMonth(dToTime)
  toTime = lastDayMonth.getTime()
  // Create a query against the collection.

  const q = query(
    userColRef,
    where('date', '>=', String(fromTime)),
    where('date', '<=', String(toTime)),
    where('status', '==', STATUS_BOOK_LESSON.PUBLISHED),
    limit(PAGINATION.REVIEW)
  )

  const lessons = []
  try {
    const docSnap = await getDocs(q)
    docSnap.forEach(doc => {
      // doc.data() is never undefined for query doc snapshots
      lessons.push(doc.data())
    })
  } catch (e) {
    console.log(e)
  }

  return lessons
}
