import {
  // User
  TODO_UPDATE_DEVICE_INFO,
  TODO_UPDATE_SETTING_INFO,
  TODO_LAYOUT_Z_INDEX_MODAL,

  // Modal
  TODO_TOGGLE_LAYOUT_ORDER_MODAL,
  TODO_SETTING_DATA_ORDER_MODAL,
  TODO_TOGGLE_LAYOUT_LOGIN_MODAL,
  TODO_TOGGLE_LAYOUT_VERIFY_EMAIL_MODAL,
  TODO_TOGGLE_LAYOUT_UPGRADE_MODAL,
  TODO_TOGGLE_LAYOUT_PAYMENT_MODAL,
  TODO_TOGGLE_LAYOUT_DEVICE_MODAL,
  TODO_SETTING_DATA_PAYMENT_MODAL
} from './type'

export const updateZIndexModal = zIndex => ({
  type: TODO_LAYOUT_Z_INDEX_MODAL,
  zIndex
})

// Modal

export const toggleLayoutOrderModal = visible => ({
  type: TODO_TOGGLE_LAYOUT_ORDER_MODAL,
  visible
})

export const settingDataOrderModal = data => ({
  type: TODO_SETTING_DATA_ORDER_MODAL,
  data
})

export const toggleLayoutLoginModal = visible => ({
  type: TODO_TOGGLE_LAYOUT_LOGIN_MODAL,
  visible
})

export const toggleLayoutVerifyEmailModal = visible => ({
  type: TODO_TOGGLE_LAYOUT_VERIFY_EMAIL_MODAL,
  visible
})

export const toggleLayoutUpgradeModal = visible => ({
  type: TODO_TOGGLE_LAYOUT_UPGRADE_MODAL,
  visible
})

export const toggleLayoutPaymentModal = visible => ({
  type: TODO_TOGGLE_LAYOUT_PAYMENT_MODAL,
  visible
})

export const settingDataDeviceModal = visible => ({
  type: TODO_TOGGLE_LAYOUT_DEVICE_MODAL,
  visible
})

export const settingDataPaymentModal = data => ({
  type: TODO_SETTING_DATA_PAYMENT_MODAL,
  data
})

export const updateDeviceInfo = deviceInfo => ({
  type: TODO_UPDATE_DEVICE_INFO,
  deviceInfo
})

export const updateSettingInfo = settingInfo => ({
  type: TODO_UPDATE_SETTING_INFO,
  settingInfo
})