import firebase_app from '../firebaseConfig'
import { getFirestore, where, collection, query, onSnapshot } from 'firebase/firestore'

// Constants
import { COLLECTION_NAME } from '../../../constants/Constants'

const db = getFirestore(firebase_app)

// All normal - CRUD
export async function subscribeDataByCollectionField(collectionName, fieldName, fieldOperation, fieldValue, callback) {
  const userColRef = collection(db, collectionName)

  // Create a query against the collection.
  const q = query(userColRef, where(fieldName, fieldOperation, fieldValue))

  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      const allData = []
      querySnapshot.forEach(doc => {
        allData.push(doc.data())
      })

      //
      allData.sort((a, b) => b.createdAt - a.createdAt)
      callback(allData, null)
    },
    error => {
      // ...
      callback(null, error)
    }
  )

  return unsubscribe
}

// Counter - Paging
export const subscribeCounterData = async (name, callback) => {
  const userColRef = collection(db, COLLECTION_NAME.COUNTER)

  // Create a query against the collection.
  const q = query(userColRef, where('_id', '==', name))

  const unsubscribe = onSnapshot(
    q,
    querySnapshot => {
      let counterData = {}
      querySnapshot.forEach(doc => {
        counterData = doc.data()
      })

      //
      callback(counterData, null)
    },
    error => {
      // ...
      callback(null, error)
    }
  )

  return unsubscribe
}
