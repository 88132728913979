// ** React Imports
import { useState, useEffect } from 'react'

//  ** Next Imports
import dynamic from 'next/dynamic'

//** Other */

// import Zoom from '@mui/material/Zoom';

// ** MUI Imports
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import CardActionArea from '@mui/material/CardActionArea'
import IconButton from '@mui/material/IconButton'
import Chip from '@mui/material/Chip'

import { styled } from '@mui/material/styles'

// ** Themes
import { useTheme } from '@mui/material/styles'

// ** Icons Imports
import WindowClose from 'mdi-material-ui/WindowClose'
import Heart from 'mdi-material-ui/Heart'
import ShareVariant from 'mdi-material-ui/ShareVariant'
import EyeOutline from 'mdi-material-ui/EyeOutline'
//  ** Utils
import { isEmptyNull, randomRangeNumber, getObjectValueWithDefaultValue } from '../../utils/utils'
import { formatStringFromDay } from '../../utils/date'

// ** Services
import { useTranslation } from 'next-i18next'
import { getTagColorByRemovedAndStatus, getTagTitleByRemovedAndStatus } from 'src/@core/data/services/blogService'

//  ** Constants
import { COLORS } from '../../../constants/Constants'
import { ENGLISH_SKILL_KEY, FULL_SKILL_DATA } from '../../../constants/PredictionConstants'

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5)
}))

// eslint-disable-next-line no-unused-vars
const StyledChip = styled(Chip)(({ theme }) => ({
  '& .MuiChip-label': {
    color: 'white'
  },
  '& .MuiChip-icon': {
    color: 'white'
  },
  '& .MuiChip-deleteIcon': {
    color: 'white'
  }
}))

const CardResultItemList = props => {
  const { t } = useTranslation()

  // ** State
  const [dataListSkill, setDataListSkill] = useState([])
  const [collapse, setCollapse] = useState(false)
  const [numberView, setNumberView] = useState(0)
  const [numberLike, setNumberLike] = useState(0)
  const [numberShare, setNumberShare] = useState(0)

  // ** Hook
  const theme = useTheme()

  useEffect(() => {
    setNumberView(randomRangeNumber(700, 1200))
    setNumberLike(randomRangeNumber(500, 1000))
    setNumberShare(randomRangeNumber(300, 600))
    //
    const dataListNumberSkill = []
    const numberSpeaking = getObjectValueWithDefaultValue(props.itemData, 'numberSpeaking', 0)
    if (numberSpeaking > 0) {
      dataListNumberSkill.push(ENGLISH_SKILL_KEY.SPEAKING)
    }
    const numberListening = getObjectValueWithDefaultValue(props.itemData, 'numberListening', 0)
    if (numberListening > 0) {
      dataListNumberSkill.push(ENGLISH_SKILL_KEY.LISTENING)
    }
    const numberReading = getObjectValueWithDefaultValue(props.itemData, 'numberReading', 0)
    if (numberReading > 0) {
      dataListNumberSkill.push(ENGLISH_SKILL_KEY.READING)
    }
    const numberWriting = getObjectValueWithDefaultValue(props.itemData, 'numberWriting', 0)
    if (numberWriting > 0) {
      dataListNumberSkill.push(ENGLISH_SKILL_KEY.WRITING)
    }

    const newDataList = dataListNumberSkill.map(itemNumberSkill => {
      const itemSkill = FULL_SKILL_DATA.find(item => item.key === itemNumberSkill)

      return itemSkill
    })

    setDataListSkill(newDataList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.itemData])

  const handleClickItem = () => {
    if (props.onClickItem) {
      props.onClickItem(props.itemData)
    }
  }

  const handleClickShowHide = event => {
    event.stopPropagation()
    event.preventDefault()
    setCollapse(!collapse)
  }

  function renderItem() {
    return (
      <CardActionArea
        sx={{
          height: '100%'
        }}
        onClick={handleClickItem}
      >
        <Card
          sx={{
            height: '100%',
            border: 0,
            boxShadow: 0,
            color: 'common.white',
            backgroundColor: COLORS[props.itemIndex % 9]
          }}
        >
          <CardContent sx={{ padding: theme => `${theme.spacing(3.25, 5, 4.5)} !important` }}>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography
                variant='h6'
                sx={{ display: 'flex', marginBottom: 0.75, alignItems: 'center', color: 'common.white' }}
              >
                {props.itemData.title}
              </Typography>
              {props.isFull !== true && collapse && (
                <IconButton size='small' onClick={handleClickShowHide}>
                  <WindowClose sx={{ fontSize: '1.375rem', color: 'common.white' }} />
                </IconButton>
              )}
            </Box>
            <Typography
              variant='subtitle2'
              mt={0}
              px={3}
              sx={{
                width: 'fit-content',
                marginBottom: 2,
                fontStyle: 'italic',
                borderRadius: 16,
                border: '1px solid #fff',
                background: '#fff',
                color: 'grey.700'
              }}
            >
              {`Ngày thi: ${formatStringFromDay(props.itemData.examDate)}`}
            </Typography>
            {dataListSkill.map(data => {
              return (
                <ListItem key={data.key}>
                  <StyledChip icon={data.icon} label={data.label} />
                </ListItem>
              )
            })}

            {!isEmptyNull(props.itemData.numLike) && (
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginTop: 4
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 3.5 }}>
                    <EyeOutline sx={{ marginRight: 1.25 }} />
                    <Typography variant='body2' sx={{ color: 'common.white' }}>
                      {props.itemData.numView || numberView}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mr: 3.5 }}>
                    <Heart sx={{ marginRight: 1.25 }} />
                    <Typography variant='body2' sx={{ color: 'common.white' }}>
                      {props.itemData.numLike || numberLike}
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ShareVariant sx={{ marginRight: 1.25 }} />
                    <Typography variant='body2' sx={{ color: 'common.white' }}>
                      {props.itemData.numShare || numberShare}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </CardContent>
        </Card>
      </CardActionArea>
    )
  }

  return (
    <Grid
      item
      xs={12}
      sm={props.isFull === true ? 12 : 6}
      md={props.isFull === true ? 12 : collapse ? 12 : 4}
      style={{
        transition: theme.transitions.create('all', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.standard
        })
      }}
    >
      {props.needBadge === true ? (
        <RibbonBadge
          marginTop={36}
          text={t(getTagTitleByRemovedAndStatus(props.itemData.isDel, props.itemData.status))}
          color={getTagColorByRemovedAndStatus(props.itemData.isDel, props.itemData.status)}
        >
          {renderItem()}
        </RibbonBadge>
      ) : (
        renderItem()
      )}
    </Grid>
  )
}

const RibbonBadge = dynamic(() => import('src/@core/layouts/components/shared-components/RibbonBadge'))

export default CardResultItemList
